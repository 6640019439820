var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-analysis-result__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full capitalize",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableData,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"data-cy":"clientList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.time)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeText(item.type))+" ")]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemPoints(item.points, item.sign))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.addedById",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.getNameById(item.addedById)))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created))+" ")]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center"},[(_vm.isDeletable)?_c('a',{staticClass:"mx-1 bg-red-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('fa-icon',{staticClass:"text-red",attrs:{"icon":"trash-alt"}})],1):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }