<template>
    <div>
        <div
            class="mt-5 w-full bg-white-pure l-box-shadow rounded px-4 py-3 flex flex-row align-center text-sm"
        >
            <div class="flex flex-row justify-between w-full">
                <div class="flex flex-row justify-between">
                    <div class="flex flex-col px-3">
                        <div class="text-black font-bold">Status</div>
                        <div>{{ userStatus }}</div>
                    </div>
                    <div class="flex flex-col px-3">
                        <div class="text-black font-bold">Current Points</div>
                        <div>{{ currentPoints }}</div>
                    </div>
                </div>
                <v-btn
                    v-if="isInvited"
                    depressed
                    color="success"
                    class="mr-2 v-btn--flat inline"
                    @click="handleInvite"
                >
                    <fa-icon icon="envelope" class="mr-1" />
                    Re-Send invite
                </v-btn>
                <v-btn
                    v-else-if="isNotInvited"
                    depressed
                    color="success"
                    class="v-btn--flat inline"
                    @click="handleInvite"
                >
                    <fa-icon icon="envelope" class="mr-1" />
                    Send invite
                </v-btn>
                <v-btn
                    v-else
                    depressed
                    color="success"
                    class="mr-2 v-btn--flat inline"
                    @click="goToTsuAccount"
                >
                    <fa-icon
                        icon="external-link-alt"
                        class="inline mr-1 group-hover:text-primary-dark"
                    />
                    TSU Account
                </v-btn>
            </div>
        </div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <div class="flex flex-row justify-between pb-4 items-center">
                <h4 class="font-display font-semibold text-left text-lg">Points History</h4>
                <v-btn
                    depressed
                    color="success"
                    class="mr-2 v-btn--flat inline"
                    @click="handleAddOrUsePoints"
                >
                    <fa-icon icon="plus" class="mr-1" />
                    Add or use points
                </v-btn>
            </div>
            <TsuManagementTable
                v-if="!userPointsLoading"
                :tableData="userPoints"
                :users="users"
                :episode="currentEpisode"
                :isAddable="$can('add', 'action_plan')"
                :isEditable="$can('edit', 'action_plan')"
            />
            <div class="flex items-center justify-center h-20 w-full" v-else>
                <Loader class="w-12 h-12" />
            </div>
        </div>
    </div>
</template>

<script>
import {ref, watch, onBeforeMount, computed} from '@vue/composition-api'
import {storeToRefs} from 'pinia'
import camelcaseKeys from 'camelcase-keys'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {useLazyQuery} from '@vue/apollo-composable'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import TsuManagementTable from '@/components/tsu-management/partial/TsuManagementTable.vue'
import {useModalStore} from '@/stores/useModalStore'
import {useUserClientStore} from '@/stores/useUserClientStore'
import {userService} from '@/services/user.service'
import Loader from '@/assets/loader.svg'

const {useActions} = createNamespacedHelpers('')
const {useState} = createNamespacedHelpers('app')

export default {
    name: 'TsuManagement',
    components: {TsuManagementTable, Loader},
    setup(_, {root}) {
        const route = root.$route
        const router = root.$router
        const clientId = computed(() => route.params.clientId)
        const users = ref([])
        const userClientStore = useUserClientStore()
        const {userPoints, reloadPoints, currentClient, userPointsLoading} =
            storeToRefs(userClientStore)
        const {getUserPoints, setUserPointsLoading, updateReloadPoints} = userClientStore
        const modalStore = useModalStore()
        const {openModal} = modalStore
        const episodes = ref([])
        const stuniId = computed(() => currentClient.value?.user?.userId || '')
        const userStatus = computed(() => {
            const statuses = staticChoices.value?.['user.statuses']
            if (!currentClient.value?.user?.status) return 'Not invited'
            const status = statuses.find((s) => s.value === currentClient.value?.user?.status)
            return status?.label
        })
        const isNotInvited = computed(
            () =>
                currentClient.value?.user?.status === null ||
                currentClient.value?.user?.status === undefined
        )
        const isInvited = computed(() => currentClient.value?.user?.status === 'INVITED')
        const isActive = computed(() => currentClient.value?.user?.status === 'ACTIVE')
        const currentPoints = computed(() => {
            let total = 0
            userPoints.value.forEach((point) => {
                if (point.sign === 'ADD') total += point.points
                else if (point.sign === 'SUBTRACT') total -= point.points
            })
            return total
        })

        const {setClientId, setCurrentEpisode} = useActions(['setClientId', 'setCurrentEpisode'])
        const {currentEpisode, staticChoices} = useState(['currentEpisode', 'staticChoices'])
        const {onResult, loading, error, refetch, load, onError} = useLazyQuery(
            GET_EPISODES_FOR_CLIENT,
            {clientId: clientId.value}
        )

        const getStaff = async () => {
            const data = await userService.getStaffUsers()
            users.value = [...data]
        }
        const handleInvite = () => {
            console.log('Invite sent')
            openModal({payload: {isInvited: isInvited.value}, modalName: 'ModalInviteYoungPerson'})
        }

        const handleAddOrUsePoints = async () => {
            openModal({payload: {}, modalName: 'ModalAddOrUsePoints'})
        }

        const goToTsuAccount = () => {
            router.push({name: 'stuni-details', params: {userId: stuniId.value}})
        }

        // const inviteStudent = (clientId) {
        //     return this.$apollo.mutate({
        //         mutation: INVITE_STUDENT,
        //         variables: {
        //             clientId: clientId
        //         }
        //     })
        // },

        onError(() => {
            console.error('error getting the list of staff users')
        })

        onResult(({data, loading: queryLoading}) => {
            if (queryLoading) return
            const episodeList = data?.episodes
            const openEpisodes = episodeList.filter((e) => e && e.status === 'OPEN')
            const otherEpisodes = episodeList.filter((e) => e && e.status !== 'OPEN')
            const allEpisodes = camelcaseKeys([...openEpisodes, ...otherEpisodes], {deep: true})
            setCurrentEpisode(allEpisodes[0])
            episodes.value = [...allEpisodes]
        })

        watch(
            currentClient,
            async () => {
                const auth0Id = currentClient.value?.user?.auth0Id
                if (!auth0Id || !currentClient.value?.user) return
                if (currentClient.value.user.status === 'ACTIVE') return
                const result = await userService.updateUserStatus(auth0Id)
                const status = result.status
                if (status === 'ACTIVE') {
                    currentClient.value.user.status = status
                }
            },
            {immediate: true}
        )
        watch(reloadPoints, async () => {
            if (reloadPoints.value) {
                await getUserPoints()
                updateReloadPoints(false)
            }
        })

        watch(
            clientId,
            async (newClientId) => {
                if (!newClientId) return
                setClientId(newClientId)
                await load()
                await getStaff()
                await getUserPoints(newClientId)
            },
            {immediate: true}
        )

        onBeforeMount(() => {
            setUserPointsLoading(true)
        })

        return {
            userPoints,
            users,
            currentEpisode,
            clientId,
            setCurrentEpisode,
            isInvited,
            isActive,
            currentPoints,
            isNotInvited,
            userStatus,
            stuniId,
            userPointsLoading,
            goToTsuAccount,
            handleInvite,
            handleAddOrUsePoints
        }
    }
}
</script>
