<template>
    <div class="c-analysis-result__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableData"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            class="a-table w-full capitalize"
            data-cy="clientList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @click:row="rowClickHandler"
        >
            <template class="" v-slot:item.date="{ item }">
                {{getFormattedDate(item.date)}}
            </template>
            <template class="" v-slot:item.time="{ item }">
                {{item.time}}
            </template>
            <template class="" v-slot:item.type="{ item }">
                {{getTypeText(item.type)}}
            </template>
            <template class="" v-slot:item.points="{ item }">
                {{getItemPoints(item.points, item.sign)}}
            </template>
            <template class="" v-slot:item.description="{ item }">
                {{item.description}}
            </template>
            <template class="" v-slot:item.addedById="{ item }">
                <span class="capitalize">{{getNameById(item.addedById)}}</span>
            </template>
            <template class="" v-slot:item.created="{ item }">
                {{getFormattedDate(item.created)}}
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }">
                <div class="editable_table__edit-record ml-2 absolute text-center">
                    <a
                        href="#"
                        v-if="isDeletable"
                        class="mx-1 bg-red-light"
                        @click.prevent="deleteHandler(item)"
                    >
                        <fa-icon icon="trash-alt" class="text-red" />
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import StatusBadge from '@/components/partials/StatusBadge'
import { mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import { POINTS_TYPES } from '@/utils/constants'
import { getFullName } from '@/utils/formatters'
import {useModalStore} from '@/stores/useModalStore'


export default {
    name: 'ActionPlanTable',
    mixins: [HttpMixin, MessageDialog, BackendHelpers, ClientHelpers],
    components: {StatusBadge},
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        searchTerm: {
            type: String,
            default: ''
        },
        filter: {
            type: Object,
            default: () => {}
        },
        summary: {
            type: Boolean,
            default: false
        },
        users: {
            type: Array,
            default: () => []
        },
        isDeletable: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            headers: [
                { text: 'Date', align: 'left', value: 'date', class: 'interaction-date--header' },
                { text: 'Time', align: 'left', value: 'time', class: 'interactionTime--header' },
                { text: 'Points', align: 'left', value: 'points', class: 'goal--header' },
                { text: 'Type', align: 'left', value: 'type', class: 'type--header' },
                { text: 'Description', align: 'left', value: 'description', class: 'w-130 commenced--header' },
                { text: 'Added by', align: 'left', value: 'addedById', class: 'added-by--header' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            emptyText: 'No case notes found for the search criteria',
            accreditationStatus: [
                {value: 'OPEN', label: 'OPEN', text: 'OPEN'},
                {value: 'CLOSED', label: 'CLOSED', text: 'CLOSED'},
            ],
            duration: 3000,
            type: '',
            message: '',
            itemsPerPage: 20,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            sortBy: ['interactionDate'],
            sortDesc: [true]
        }
    },
    methods: {
        rowClickHandler(item) {
            this.$root.$emit('create-case-note', item)
        },
        filterChangeHandler(filters) {
            // first we get the key and there should only be one at this stage
            let filter = {...this.filter}, key = Object.keys(filters)[0]
            if (filters[key] && filters[key].length === 0) {
                if (filter.columnFilters[key] != null) {
                    filter.columnFilters[key] = null
                }
            } else {
                filter.columnFilters = {...this.filter.columnFilters, ...filters}
            }
            this.$emit('filter-change', ...[filter, true])
        },
        isRowLocked(row) {
            // if we don't have the user yet then just return false
            if (!this.$auth.user) return true

            return row.status === 'PAYMENT_PENDING' && !this.isAdmin
        },
        rowClassName({row}) {
            if (this.isRowLocked(row)) {
                return 'locked-row'
            }
            return 'cursor-pointer'
        },
        getText(id, listName) {
            const foundObj = this[listName]?.find(t => t.value === id)
            return foundObj ? foundObj.text : ''
        },
        getStatusColour(status) {
            switch(status) {
                case 'OPEN':
                    return 'text-green'
                case 'PENDING':
                    return 'text-yellow'
                default:
                    return 'text-grey'
            }
        },
        getFormattedDate(date) {
            if (!date) return ''
            return format(parseISO(date), 'dd/MM/yyyy')
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        types() {
            return this.staticChoices['caseNote.type'] || []
        },
        methods() {
            return this.staticChoices['caseNote.method'] || []
        },
        durations() {
            return this.staticChoices['caseNote.duration'] || []
        },
        states() {
            let states = this.staticChoices['generic_address.state'] || []
            if (states && states.length > 0) {
                states = states.map(s => {
                    return {
                        label: s.label,
                        value: s.value,
                        text: s.value
                    }
                })
            }
            return states
        },
    },
    watch: {
        tableData() {
            let emptyRow
            if (this.addingNewRow && this.currentNewId) emptyRow = this.tableDataArr.find(tda => tda.vocationalEdId === this.currentNewId)
            this.tableDataArr = this.tableData

            // if (this.tableDataArr && this.tableDataArr.length === 0) this.addNewVocationalEd()
            if (emptyRow && !this.tableData.find(tda => tda.vocationalEdId === this.currentNewId)) {
                this.addingNewRow = false
                this.tableDataArr.push(emptyRow)
            }
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        },
        tableDataArr: {
            handler() {
                this.$emit('table-data-change', this.tableDataArr)
            },
            deep: true
        },
        episode() {
            this.cancelHandler()
            this.defaultNewObject.episodeId = this.episode.episodeId
        }
    },
    setup(props) {
        const modalStore = useModalStore()
        const {openModal} = modalStore
        const computedUsers = computed(() => props.users)
        const getTypeText = (type) => POINTS_TYPES[type]
        const getItemPoints = (points, sign) => {
            return sign === 'ADD' ? `+${points}` : `-${points}`
        }

        const getNameById = (id) => {
            const user = computedUsers.value.find(t => t.userId === id)
            console.log('===== did we find a user?', user)
            return getFullName(user)
        }

        const deleteHandler = (points) => {
            openModal({payload: { ...points }, modalName: 'ModalConfirmDeletePoints'})
        }

        return { getItemPoints, getTypeText, getNameById, deleteHandler }
    }
}
</script>
